<template>
    <div class="privacy-policy" :class="{ rtl: isHebrew }">
        <button class="toggle-btn" @click="toggleLanguage">
            <i class="material-icons">language</i> {{ isHebrew ? 'English' : 'עברית' }}
        </button>

        <h1>{{ privacyPolicy.title }}</h1>
        <p :style="{ direction: isHebrew ? 'rtl' : 'ltr' }">{{ privacyPolicy.lastUpdated }}</p>
        <br>

        <h2 :style="{ direction: isHebrew ? 'rtl' : 'ltr' }">{{ privacyPolicy.whoWeAreTitle }}</h2>
        <p v-html="privacyPolicy.whoWeAreText" :style="{ direction: isHebrew ? 'rtl' : 'ltr' }"></p>
        <br>

        <h2 :style="{ direction: isHebrew ? 'rtl' : 'ltr' }">{{ privacyPolicy.dataCollectionTitle }}</h2>
        <p :style="{ direction: isHebrew ? 'rtl' : 'ltr' }">{{ privacyPolicy.dataCollectionText }}</p>
        <br>

        <h2 :style="{ direction: isHebrew ? 'rtl' : 'ltr' }">{{ privacyPolicy.pageDataTitle }}</h2>
        <ul :style="{ direction: isHebrew ? 'rtl' : 'ltr' }">
            <li v-for="(info, key) in privacyPolicy.pageData" :key="key"
                :style="{ direction: isHebrew ? 'rtl' : 'ltr' }">
                <strong>{{ info.title }}:</strong> {{ info.description }}
            </li>
        </ul>
        <br>

        <h2 :style="{ direction: isHebrew ? 'rtl' : 'ltr' }">{{ privacyPolicy.howWeUseDataTitle }}</h2>
        <p :style="{ direction: isHebrew ? 'rtl' : 'ltr' }">{{ privacyPolicy.howWeUseDataText }}</p>
        <br>

        <h2 :style="{ direction: isHebrew ? 'rtl' : 'ltr' }">{{ privacyPolicy.cookiesTitle }}</h2>
        <p :style="{ direction: isHebrew ? 'rtl' : 'ltr' }">{{ privacyPolicy.cookiesText }}</p>
        <br>

        <h2 :style="{ direction: isHebrew ? 'rtl' : 'ltr' }">{{ privacyPolicy.thirdPartyTitle }}</h2>
        <p v-html="privacyPolicy.thirdPartyText" :style="{ direction: isHebrew ? 'rtl' : 'ltr' }"></p>
        <br>

        <h2 :style="{ direction: isHebrew ? 'rtl' : 'ltr' }">{{ privacyPolicy.securityTitle }}</h2>
        <p :style="{ direction: isHebrew ? 'rtl' : 'ltr' }">{{ privacyPolicy.securityText }}</p>
        <br>

        <h2 :style="{ direction: isHebrew ? 'rtl' : 'ltr' }">{{ privacyPolicy.rightsTitle }}</h2>
        <p :style="{ direction: isHebrew ? 'rtl' : 'ltr' }">{{ privacyPolicy.rightsText }}</p>
        <br>

        <h2>{{ privacyPolicy.retentionTitle }}</h2>
        <p :style="{ direction: isHebrew ? 'rtl' : 'ltr' }">{{ privacyPolicy.retentionText }}</p>
        <br>

        <h2 :style="{ direction: isHebrew ? 'rtl' : 'ltr' }">{{ privacyPolicy.complianceTitle }}</h2>
        <p :style="{ direction: isHebrew ? 'rtl' : 'ltr' }">{{ privacyPolicy.complianceText }}</p>
        <br>

        <h2>{{ privacyPolicy.changesTitle }}</h2>
        <p :style="{ direction: isHebrew ? 'rtl' : 'ltr' }">{{ privacyPolicy.changesText }}</p>
        <br>

        <h2 :style="{ direction: isHebrew ? 'rtl' : 'ltr' }">{{ privacyPolicy.contactTitle }}</h2>
        <p :style="{ direction: isHebrew ? 'rtl' : 'ltr' }">
            {{ privacyPolicy.contactText }}<br>
            <strong>Email:</strong> operation@rosman.co.il
        </p>
    </div>
</template>

<script setup>
import { ref, computed } from 'vue';

const isHebrew = ref(true);

const privacyPolicy = computed(() => {
    return isHebrew.value
        ? {
            title: "מדיניות פרטיות",
            lastUpdated: "עודכן לאחרונה: 6 במרץ 2025",
            whoWeAreTitle: "מי אנחנו?",
            whoWeAreText: `אנו רוסמן מרקט, רשת קמעונאית וספקית שירותי לוגיסטיקה. אנו מפעילים אתר זה לניהול לוגיסטי פנימי ולפרסום מידע ציבורי, כולל מיקומי סניפים, קטלוג מוצרים ומשרות פנויות. בשימוש באתר שלנו, אתה מסכים ל <a href="https://rosmannext.web.app/terms" target="_blank" rel="noopener noreferrer">תנאים והגבלות</a> שלנו.`,
            dataCollectionTitle: "איזה מידע אנו אוספים?",
            dataCollectionText: "אנו אוספים מידע אישי רק כאשר משתמשים מגישים טפסים באתר (כגון פניות לקוחות או בקשות עבודה).",
            pageDataTitle: "מידע הנאסף על פי עמודי האתר",
            pageData: {
                catalog: { title: "עמוד הקטלוג", description: "מציג מוצרים מסודרים לפי קטגוריות. ❌ אין איסוף מידע אישי." },
                specials: { title: "קטלוג מבצעים", description: "רשימת מוצרים בהנחה. ❌ אין איסוף מידע אישי." },
                branches: { title: "סניפים", description: "רשימת סניפים ושעות פעילות. ❌ אין איסוף מידע אישי." },
                contact: { title: "צור קשר", description: "משתמשים יכולים לשלוח הודעה על מנת שנחזור אליהם. ✅ בעמוד זה אנו אוספים שם מלא, שם חברה, אימייל, טלפון והודעה של הפונה." },
                wanted: { title: "עמוד דרושים", description: "מפורסמות משרות פנויות. ✅ בעמוד זה אנו אוספים קורות חיים, שם, אימייל וטלפון של הפונה." },
            },
            howWeUseDataTitle: "כיצד אנו משתמשים במידע?",
            howWeUseDataText: "הנתונים משמשים למענה לפניות ולניהול בקשות עבודה. אין שיתוף מידע עם צד שלישי מלבד לצרכים תפעוליים הכרחיים.",
            cookiesTitle: "האם אנו משתמשים בקובצי Cookie?",
            cookiesText: "לא, האתר שלנו אינו משתמש בקובצי Cookie או מעקב אחר מבקרים.",
            thirdPartyTitle: "שירותי צד שלישי",
            thirdPartyText: `אנו משתמשים ב-Mailgun ו-Cloudflare לשליחת דוא"ל בלבד. Mailgun מעבדים כתובות דוא"ל, תוכן ההודעה ונתונים רלוונטיים בהתאם ל
            <a href="https://www.mailgun.com/legal/privacy-policy/" target="_blank" rel="noopener noreferrer">מדיניות הפרטיות</a> שלהם. 
            Cloudflare מעבדים נתונים בהתאם ל
            <a href="https://www.cloudflare.com/privacypolicy/" target="_blank" rel="noopener noreferrer">מדיניות הפרטיות</a> שלהם.`,
            securityTitle: "כיצד אנו מגנים על הנתונים?",
            securityText: "אנו נוקטים אמצעי אבטחה מחמירים כדי לשמור על פרטיות הנתונים, לרבות הצפנה ובקרת גישה מוגבלת.",
            rightsTitle: "זכויותיך על הנתונים",
            rightsText: "באפשרותך לבקש עותק מהמידע, תיקון, מחיקה או להסיר את הסכמתך לשימוש בו.",
            retentionTitle: "כמה זמן נשמור את הנתונים?",
            retentionText: "פניות נשמרות עד 12 חודשים, בקשות עבודה עד 6 חודשים אלא אם כן התקבל המועמד לעבודה.",
            complianceTitle: "ציות לחוק",
            complianceText: "אנו עומדים בדרישות החוק בישראל.",
            changesTitle: "שינויים במדיניות",
            changesText: "אנו עשויים לעדכן מדיניות זו מעת לעת ולפרסם שינויים באתר.",
            contactTitle: "יצירת קשר",
            contactText: "לשאלות נוספות, ניתן ליצור איתנו קשר בכתובת:",
        }
        : {
            title: "Privacy Policy",
            lastUpdated: "Last updated: March 6, 2025",
            whoWeAreTitle: "Who Are We?",
            whoWeAreText: `We are Rosman Market, working in supermarkets retail and logistics. We operate this website for internal logistics and public information, including store locations, product catalogs, and job postings. By using our website, you agree to our <a href="https://rosmannext.web.app/terms" target="_blank" rel="noopener noreferrer">Terms and Conditions</a>.`,
            dataCollectionTitle: "What Data Do We Collect?",
            dataCollectionText: "We only collect personal data when users voluntarily submit forms (such as job applications or contact inquiries).",
            pageDataTitle: "Page Data Collection",
            pageData: {
                catalog: { title: "Catalog Page", description: "Displays products by category. ❌ No personal data collected." },
                specials: { title: "Specials Catalog", description: "Lists discounted products. ❌ No personal data collected." },
                branches: { title: "Branches", description: "Lists store locations and hours. ❌ No personal data collected." },
                contact: { title: "Contact Us", description: "Users can submit a message. ✅ Collects name, company, email, phone, and message." },
                wanted: { title: "Wanted Page", description: "Lists job openings. ✅ Collects resume, name, email, and phone." },
            },
            howWeUseDataTitle: "How Do We Use Your Data?",
            howWeUseDataText: "Data is used for communication and job processing. We do not sell or share data.",
            cookiesTitle: "Do We Use Cookies?",
            cookiesText: "No, we do not use cookies or tracking.",
            thirdPartyTitle: "Third-Party Services",
            thirdPartyText: `We use Mailgun and Cloudflare for emails only. Mailgun processes email addresses, message content, and related data in accordance with its 
            <a href="https://www.mailgun.com/legal/privacy-policy/" target="_blank" rel="noopener noreferrer">Privacy Policy</a>. Cloudflare processes data according to its 
            <a href="https://www.cloudflare.com/privacypolicy/" target="_blank" rel="noopener noreferrer">Privacy Policy</a>.`,
            securityTitle: "How Do We Protect Data?",
            securityText: "We apply strict security measures including encryption and access control.",
            rightsTitle: "Your Rights Over Data",
            rightsText: "You can request access, correction, or deletion of your data.",
            retentionTitle: "Data Retention",
            retentionText: "Contact form data is stored for 12 months, job applications for 6 months.",
            complianceTitle: "Legal Compliance",
            complianceText: "We comply with Israeli laws.",
            changesTitle: "Policy Changes",
            changesText: "We may update this policy periodically.",
            contactTitle: "Contact Information",
            contactText: "For inquiries, contact us at:",
        };
});

const toggleLanguage = () => {
    isHebrew.value = !isHebrew.value;
};
</script>
<style scoped>
.privacy-policy {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
    text-align: left;
    color: white;
    direction: ltr;
}

.privacy-policy.rtl {
    text-align: right;
    direction: rtl;
}

.toggle-btn {
    display: flex;
    align-items: center;
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 15px;
    font-size: 16px;
    cursor: pointer;
    margin-bottom: 20px;
    border-radius: 5px;
}

.toggle-btn i {
    margin-right: 8px;
}

.toggle-btn:hover {
    background-color: #0056b3;
}

h1,
h2 {
    color: #ddd;
}

p {
    line-height: 1.6;
}

:deep(a) {
    color: lightskyblue;
}
</style>
